import React, { useState } from "react";
import "./ProduktKontakt.css";
import { database } from "../firebaseConfig"; // Importiere die Firebase-Datenbank
import { ref, push } from "firebase/database";

function KontaktForm2({ submitForm }) {
  const [Submitted, setSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  // Validierungsfunktionen
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    // Erlaubt Ziffern, Leerzeichen, +, -, und Klammern ()
    const phoneRegex = /^[\d\s+()\-]{6,20}$/;
    return phone === "" || phoneRegex.test(phone); // Optional, daher leer erlaubt
  };

  const validateForm = (formData) => {
    const errors = {};
    
    // E-Mail validieren
    const email = formData.get("email");
    if (!validateEmail(email)) {
      errors.email = "Bitte geben Sie eine gültige E-Mail-Adresse ein";
    }
    
    // Telefon validieren (wenn vorhanden)
    const phone = formData.get("telefon");
    if (phone && !validatePhone(phone)) {
      errors.telefon = "Bitte geben Sie eine gültige Telefonnummer ein";
    }
    
    return errors;
  };

  const sendRequest = (e) => {
    e.preventDefault();

    // Alle Eingaben des Benutzers sammeln
    const formData = new FormData(e.target);
    
    // Formular validieren
    const errors = validateForm(formData);
    setFormErrors(errors);
    
    // Wenn es Fehler gibt, Absenden abbrechen
    if (Object.keys(errors).length > 0) {
      return;
    }
    
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      telefon: formData.get("telefon"),
      adresse: formData.get("adresse"),
      plz: formData.get("plz"),
      produkt: formData.get("produkt"),
      stueckzahl: formData.get("stueckzahl"),
      message: formData.get("message"),
      timestamp: new Date().toISOString(),
    };

    // Daten in die Realtime Database speichern
    const dbRef = ref(database, "/Anfragen");
    push(dbRef, data)
      .then(() => {
        alert("Die Anfrage wurde erfolgreich gesendet");
        e.target.reset();
        setFormErrors({});
        setSubmitted(true);
        submitForm(); // Zeige das Success-Komponent an
      })
      .catch((error) => {
        console.error("Fehler beim Speichern der Anfrage:", error);
        alert("Es gab einen Fehler beim Senden der Anfrage. Bitte versuchen Sie es später erneut.");
      });
  };

  return (
    <div className="formular">
      <form onSubmit={sendRequest} id="contact-form" name="contact-form">
        <input
          className="input"
          placeholder="Vor & Nachname*"
          name="name"
          type="text"
          required
        />
        <div className="input-container">
          <input
            className={`input ${formErrors.email ? "input-error" : ""}`}
            placeholder="E-Mail-Adresse*"
            type="email"
            name="email"
            required
          />
          {formErrors.email && <div className="error-message">{formErrors.email}</div>}
        </div>
        <div className="input-container">
          <input
            className={`input ${formErrors.telefon ? "input-error" : ""}`}
            placeholder="Telefonnummer"
            name="telefon"
            type="text"
          />
          {formErrors.telefon && <div className="error-message">{formErrors.telefon}</div>}
        </div>
        <div className="input-container">
          <input
            className="input"
            placeholder="Adresse"
            name="adresse"
            type="text"
          />
        </div>
        <div className="input-container">
          <input
            className="input"
            placeholder="PLZ & Ort"
            name="plz"
            type="text"
          />
        </div>
        <select className="input3" name="produkt" required>
          <option value="Nichts Ausgewählt">Produkt Auswählen</option>
          <option value="Faltkartons">Faltkartons</option>
          <option value="Rohe Kartonagen">Rohe Kartonagen</option>
          <option value="Überzogene Kartonagen">Überzogene Kartonagen</option>
          <option value="Luxuskartonagen">Luxuskartonagen</option>
          <option value="Diplomrollen">Diplomrollen</option>
          <option value="Klarsicht-Verpackungen">Klarsicht-Verpackungen</option>
          <option value="Aktenkartons">Aktenkartons</option>
          <option value="Schulschachteln">Schulschachteln</option>
          <option value="Teebäckerei Kartons">Teebäckerei Kartons</option>
          <option value="Spulen">Spulen</option>
          <option value="Sonstiges">Sonstiges</option>
        </select>
        <select className="input3" name="stueckzahl" required>
          <option value="1-10">1-10 Stück</option>
          <option value="11-100">11-100 Stück</option>
          <option value="101-200">101-200 Stück</option>
          <option value="201-500">201-500 Stück</option>
          <option value="500+">500+ Stück</option>
        </select>
        <textarea
          className="input5"
          placeholder="Wie können wir Ihnen weiterhelfen?"
          type="text"
          name="message"
        />
        <div className="checkbox_container">
          <input type="checkbox" id="kontakt" name="kontakt" required />
          <label htmlFor="kontakt">
            Ich akzeptiere die Datenschutzbestimmungen
          </label>
        </div>
        <button type="submit" value="send">
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <p>Absenden</p>
            <img style={{filter: 'invert(1)', width: '18px', marginLeft: '5px'}} src="./img/right.svg" alt="" />
            </div>
        </button>
      </form>
    </div>
  );
}

export default KontaktForm2;
