import React from 'react';
import "./Kontakt.css";
import ProduktKontakt_2 from "../Produkte/ProduktKontakt_2.js";
import {Helmet} from "react-helmet";

function Kontakt() {
    return (
        <div className="weber-kontakt-information">
            <Helmet>
                <title>Kontakt - Peter Weber Kartonagen</title>
                <meta name="description" content="Unsere neue Firmenadresse ist: Himbergerstraße 2 1100 Wien. Besuchen Sie uns vor Ort oder senden Sie eine Anfrage über unser Kontaktformular." />
            </Helmet>
            
            <div className="weber-kontakt-layout">
                <div className="weber-kontakt-formular-bereich">
                    <h2 className="weber-kontakt-formular-titel">Kontaktieren Sie uns</h2>
                    <div className="weber-kontakt-formular-container">
                        <ProduktKontakt_2 />
                    </div>
                </div>
                
                <div className="weber-kontakt-info-bereich">
                    <h2 className="weber-kontakt-info-titel">Unsere Kontaktdaten</h2>
                    <div className="weber-kontakt-adresse-box">
                        <div className="weber-kontakt-box-container">
                            <div className="weber-kontakt-box-inhalt">
                                <p>Unsere Adresse:</p>
                                <h3>Himbergerstraße 2 <br/> <span>1100 Wien</span></h3>
                                <h4>Öffnungszeiten</h4>
                                <h4>Montag-Freitag: <span>07:00-15:00</span></h4>
                            </div>
                            <div className="weber-kontakt-box-icon">
                                <img src="./img/Warehouse.svg" alt="Warehouse"/>
                            </div>
                        </div>
                    </div>

                    <div className="weber-kontakt-telefon-box">
                        <div className="weber-kontakt-box-container">
                            <div className="weber-kontakt-box-inhalt">
                                <p>So erreichen sie uns:</p>
                                <h3>Telefon & Email</h3>
                                <h4>Telefon: <span>+43 1 493 35 35</span></h4>
                                <h4>Email: <span>office@kartonagen-weber.at</span></h4>
                            </div>
                            <div className="weber-kontakt-box-icon">
                                <img src="./img/Telephone.svg" alt="Telephone Logo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Kontakt
