import React, { useState, useEffect } from 'react';
import '../Produkte/ProduktKontakt.css';

function Toast({ message, type = 'success', duration = 5000, onClose }) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const handleClose = () => {
    setVisible(false);
    if (onClose) onClose();
  };

  if (!visible) return null;

  return (
    <div className={`toast ${type}`}>
      <div>{message}</div>
      <button className="toast-close" onClick={handleClose}>×</button>
    </div>
  );
}

// Toast-Container für mehrere Toast-Nachrichten
export function ToastContainer({ toasts, removeToast }) {
  return (
    <div className="toast-container">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          message={toast.message}
          type={toast.type}
          duration={toast.duration}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </div>
  );
}

export default Toast; 