import React, { useState } from "react";
import "./ProduktKontakt.css";
import { database } from "../firebaseConfig"; // Importiere die Firebase-Datenbank
import { ref, push } from "firebase/database";

function KontaktForm3({ submitForm }) {
  const [Submitted, setSubmitted] = useState(false);

  const sendRequest = (e) => {
    e.preventDefault();

    // Alle Eingaben des Benutzers sammeln
    const formData = new FormData(e.target);
    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      telefon: formData.get("telefon"),
      adresse: formData.get("adresse"),
      plz: formData.get("plz"),
      deckel: formData.get("deckel"),
      size: formData.get("size"),
      art: formData.get("art"),
      stueckzahl: formData.get("stueckzahl"),
      message: formData.get("message"),
      produkt: "Schulschachteln", // Fester Produktname
      timestamp: new Date().toISOString(),
    };

    // Daten in die Realtime Database speichern
    const dbRef = ref(database, "/Anfragen");
    push(dbRef, data)
      .then(() => {
        alert("Die Anfrage wurde erfolgreich gesendet");
        e.target.reset();
        setSubmitted(true);
        submitForm(); // Zeige das Success-Komponent an
      })
      .catch((error) => {
        console.error("Fehler beim Speichern der Anfrage:", error);
        alert("Es gab einen Fehler beim Senden der Anfrage. Bitte versuchen Sie es später erneut.");
      });
  };

  return (
    <div className="formular">
      <form onSubmit={sendRequest} id="contact-form" name="contact-form">
        <input
          className="input"
          placeholder="Vor & Nachname*"
          name="name"
          type="text"
          required
        />
        <input
          className="input"
          placeholder="E-Mail-Adresse*"
          type="email"
          name="email"
          required
        />
        <input
          className="input"
          placeholder="Telefonnummer*"
          name="telefon"
          type="text"
          required
        />
        <input
          className="input"
          placeholder="Adresse"
          name="adresse"
          type="text"
          required
        />
        <input
          className="input"
          placeholder="PLZ"
          name="plz"
          type="text"
        />
        <select className="input4" name="deckel" required>
          <option value="Karton">Karton-Deckel</option>
          <option value="Klarsicht">Klarsichtmaterial</option>
          <option value="Kein Deckel">Kein Deckel</option>
        </select>
        <select className="input4" name="size" required>
          <option value="A3">DIN A3 (430x310x50/50mm)</option>
          <option value="A4">DIN A4 (310x235x50/50mm)</option>
          <option value="A5">DIN A5 (220x160x50/50mm)</option>
          <option value="A6">DIN A6 (155x110x50/50mm)</option>
          <option value="A7">DIN A7 (105x075x25/25mm)</option>
        </select>
        <select className="input4" name="art" required>
          <option value="Graukarton">Graukarton (Geritzt, Eckengeheftet)</option>
          <option value="Wellpappe Weiss">Wellpappe Weiss (Gestanzt, Flach, Selbstau)</option>
        </select>
        <input
          className="input"
          placeholder="Stückzahl"
          name="stueckzahl"
          type="number"
        />
        <textarea
          className="input5"
          placeholder="Zusätzliche Informationen"
          type="text"
          name="message"
        />
        <div className="checkbox_container">
          <input type="checkbox" id="kontakt" name="kontakt" required />
          <label htmlFor="kontakt">
            Ich akzeptiere die Datenschutzbestimmungen
          </label>
        </div>
        <button type="submit" value="send">
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <p>Absenden</p>
            <img style={{filter: 'invert(1)', width: '18px', marginLeft: '5px'}} src="./img/right.svg" alt="" />
            </div>
        </button>
      </form>
    </div>
  );
}

export default KontaktForm3;
