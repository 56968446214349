import React, { useEffect, useState } from "react";
import { database } from "../firebaseConfig";
import { ref, onValue } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "./BackendAnalytics.css";
import MonthAnalytics from "./MonthAnalytics"; // Import der MonthAnalytics-Komponente
import PopularProductAnalytics from "./PopularProductAnalytics";

function BackendAnalytics() {
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    let unsubscribe;

    const fetchEntries = () => {
      const dbRef = ref(database, "/Anfragen");

      onValue(
        dbRef,
        (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const formattedEntries = Object.keys(data)
              .map((key) => ({ id: key, ...data[key] }))
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sortiere nach Datum
            setEntries(formattedEntries);
          } else {
            setEntries([]);
          }
          setLoading(false);
        },
        (error) => {
          console.error("Fehler beim Abrufen der Daten:", error);
          setAuthError(true); // Fehler beim Zugriff auf Datenbank
          setLoading(false);
        }
      );
    };

    unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchEntries();
      } else {
        setAuthError(true); // Kein authentifizierter Benutzer
        setLoading(false);
      }
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  if (loading) {
    return <div className="backend-analytics-loading">Laden...</div>;
  }

  if (authError) {
    return (
      <div className="backend-analytics-auth-error">
        Sie sind nicht berechtigt, diese Daten anzuzeigen. Bitte melden Sie sich an.
      </div>
    );
  }

  if (entries.length === 0) {
    return <div className="backend-analytics-no-entries">Keine Einträge gefunden.</div>;
  }

  return (
    <div className="backend-analytics">
      <h1>Backend Analytics</h1>

      {/* MonthAnalytics-Komponente anzeigen */}
      <MonthAnalytics entries={entries} />
      <PopularProductAnalytics entries={entries} />

      <div className="backend-analytics-entry-list">
        {entries.map((entry) => (
          <div key={entry.id} className="backend-analytics-entry-container">
            <h2>Eintrag: {entry.name || "Unbekannt"}</h2>
            <p>
              <strong>E-Mail:</strong> {entry.email || "Nicht angegeben"}
            </p>
            <p>
              <strong>Telefon:</strong> {entry.telefon || "Nicht angegeben"}
            </p>
            <p>
              <strong>Adresse:</strong> {entry.adresse || "Nicht angegeben"}
            </p>
            <p>
              <strong>PLZ & Ort:</strong> {entry.plz || "Nicht angegeben"}
            </p>
            <p>
              <strong>Produkt:</strong> {entry.produkt || "Nicht angegeben"}
            </p>
            <p>
              <strong>Stückzahl:</strong> {entry.stueckzahl || "Nicht angegeben"}
            </p>
            <p>
              <strong>Nachricht:</strong> {entry.message || "Nicht angegeben"}
            </p>
            <p>
              <strong>Erstellungsdatum:</strong>{" "}
              {new Date(entry.timestamp).toLocaleString()}
            </p>
            {entry.imageUrl && (
              <div>
                <strong>Hochgeladenes Bild:</strong>
                <br />
                <img
                  src={entry.imageUrl}
                  alt="Hochgeladenes Bild"
                  className="backend-analytics-entry-image"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default BackendAnalytics;
